import { useRouter } from 'next/router';
import Input from '~/components/common/input';
import classNames from 'classnames';
import { filterChangeDataLayer } from '~/analytics/gtm/events';
import { useEffect } from 'react';
import { useState } from 'react';

type CheckboxButtonProps = {
   name: string;
   label: any;
   id: string;
   onChange?: (name: string, checked: boolean) => void;
   isChecked?: boolean;
   theme?: 'tab' | 'chip';
   icon?: string;
};

const CheckboxButton = ({
   name,
   label,
   id,
   onChange,
   theme,
   icon,
   isChecked,
}: CheckboxButtonProps) => {
   const router = useRouter();
   const [checked, setChecked] = useState(isChecked || false);

   useEffect(() => {
      if (isChecked) return;
      setChecked(router?.query?.categories?.includes(name) === true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [router?.query]);

   return (
      <div className="flex relative min-w-[fit-content]">
         <Input
            type="checkbox"
            className="absolute z-10 w-full h-full opacity-0"
            name={name}
            label={label}
            id={id}
            checked={checked}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
               setChecked(e.currentTarget.checked);
               onChange?.(name, e.currentTarget.checked);
               filterChangeDataLayer({ name: label, value: String(e.currentTarget.checked) });
            }}
         />
         <div
            className={classNames(
               'flex items-center cursor-pointer px-3 py-1',
               theme === 'tab' ? 'flex-col' : 'bg-white border border-[#A5A5A5] flex-row',
               theme === 'chip' ? 'rounded-xl ' : '',
               checked && theme === 'tab' && 'border-b-2 border-sta-light',
               checked && theme === 'chip' && '!bg-sta-primary border-sta-primary'
            )}>
            {icon ? (
               <img
                  className={classNames(
                     'object-contain grayscale',
                     theme === 'chip' ? '!w-3.5 !h-3.5 !grayscale-0' : '!w-6 !h-6',
                     checked && theme === 'tab' && 'grayscale-0',
                     checked && theme === 'chip' && 'invert brightness-0'
                  )}
                  src={icon}
                  alt="Category Icon"
               />
            ) : null}
            <span
               className={classNames(
                  'text-sm font-semibold px-2 py-1',
                  checked && theme === 'tab' ? '!text-sta-light' : 'text-sta-subdued',
                  checked && theme === 'chip' ? '!text-white' : 'text-sta-subdued'
               )}>
               {label}
            </span>
         </div>
      </div>
   );
};
export default CheckboxButton;
