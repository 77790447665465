import capitalize from 'lodash/capitalize';
import Head from 'next/head';
import Script from 'next/script';

export type SearchPageSchemaResultItem = {
   name: string;
   image: string;
   url: string;
   offers: {
      url: string;
      priceCurrency: string;
      price: string | number;
   };
};

interface SearchResultsSchemaProps {
   title: string;
   results: SearchPageSchemaResultItem[];
}

export const SearchResultsSchema = (props: SearchResultsSchemaProps) => {
   const getListItemForEvent = (item: SearchPageSchemaResultItem, index: number) => {
      return {
         '@type': 'ListItem',
         position: index + 1,
         item: {
            '@type': 'Event',
            ...item,
         },
      };
   };

   const schema = {
      '@context': 'http://schema.org',
      '@type': 'SearchResultsPage',
      mainEntity: [
         {
            '@type': 'ItemList',
            name: capitalize(props.title),
            itemListOrder: 'http://schema.org/ItemListOrderAscending',
            itemListElement: props.results.map(getListItemForEvent),
         },
      ],
   };

   return (
      <Head>
         <Script
            type="application/id+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schema, null, 3) }}
         />
      </Head>
   );
};
