import { Fragment, useEffect } from 'react';
import { NextLink, Translate, useTranslate } from '~/i18n';

import { CardItem } from '../experiences/card-item';
import { ExperienceProps } from '~/interfaces/experience';
import { LineArrowRight } from '~/svgs/common';
import ReactPaginate from 'react-paginate';
import { normalizeExperience } from '~/utils/normalize-data';
import { productImpressionDataLayer } from '~/analytics/gtm/ecommerce';
import { useAuth } from '~/auth';
import { useRouter } from 'next/router';

type FiltersPageExperiencesListProps = {
   current_page: number;
   data: ExperienceProps[];
   total: number;
   per_page: number;
   last_page: number;
   featured?: ExperienceProps[];
};
export const FiltersPageExperiencesList = ({
   current_page,
   data,
   per_page,
   total,
}: // last_page,
FiltersPageExperiencesListProps) => {
   const { dir, locale } = useTranslate();
   const { authenticated, user } = useAuth();
   const count = Math.ceil(total ? Math.ceil(total / per_page) : 0);
   const router = useRouter();
   const onPageChange = ({ selected }: any) => {
      const obj = { ...router.query };
      if (selected) obj.page = `${selected + 1}`;
      else delete obj.page;
      router.push({ query: obj });
   };

   useEffect(() => {
      if (data?.length) {
         productImpressionDataLayer({
            products: data.map((item, index) =>
               normalizeExperience({
                  item,
                  position: index + 1,
                  list: router?.query?.query ? 'Search Result' : 'Experience Listing',
               })
            ),
            user: {
               email: user?.email || '',
               user_id: user?.user_id || '',
               login_status: authenticated ? 'login' : '',
            },
         });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data, user?.email]);

   // const SHOW_FEATURED = current_page === last_page || data.length <= 1 || !data.length;

   return (
      <div className="pb-5">
         {!data.length ? (
            <div className="my-16">
               <span className="block mb-4 text-3xl font-bold">
                  <Translate id="filters:no results title" />
               </span>
               <span className="block mb-10 text-xl">
                  <Translate id="filters:no results subtitle" />
               </span>
               <div className="flex items-center space-x-6 rtl:space-x-reverse">
                  <NextLink
                     href="/search"
                     replace
                     className="px-4 py-1.5 text-base font-semibold rounded-lg whitespace-nowrap text-white bg-sta-primary hover:bg-sta-light">
                     <Translate id="filters:no results button" />
                  </NextLink>
               </div>
            </div>
         ) : (
            <Fragment>
               <div className="grid mb-12 gap-6 lg:gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center">
                  {data.map(({ city, title, name, ...card }) => (
                     <CardItem
                        key={card._id}
                        name={title?.[locale] || name}
                        city={city?.name}
                        city_slug={city?.slug}
                        {...card}
                     />
                  ))}
               </div>
            </Fragment>
         )}
         {total > 0 && (
            <ReactPaginate
               previousLabel={
                  <span className="rounded-full border bg-sta-primary text-white p-2.5">
                     {dir === 'rtl' ? (
                        <LineArrowRight className="w-5 h-5" />
                     ) : (
                        <LineArrowRight className="w-5 h-5 transform rotate-180" />
                     )}
                  </span>
               }
               nextLabel={
                  <span className="rounded-full border bg-sta-primary text-white p-2.5">
                     {dir === 'rtl' ? (
                        <LineArrowRight className="w-5 h-5 transform rotate-180" />
                     ) : (
                        <LineArrowRight className="w-5 h-5" />
                     )}
                  </span>
               }
               pageCount={count}
               pageRangeDisplayed={2}
               marginPagesDisplayed={1}
               onPageChange={onPageChange}
               containerClassName="flex items-center justify-center space-x-[18.5px] rtl:space-x-reverse mt-10"
               activeLinkClassName="font-semibold text-primary-color"
               previousClassName="inline-block"
               previousLinkClassName="flex items-center text-subheadings"
               nextClassName="inline-block"
               nextLinkClassName="flex items-center text-subheadings"
               pageClassName="text-[#ADB5BD] inline-block"
               breakClassName="inline-block"
               forcePage={current_page - 1}
               disabledClassName="text-[#ADB5BD] pointer-events-none"
            />
         )}
         {/* {featured?.length && SHOW_FEATURED ? (
            <div className="mt-8">
               <span className="block mb-6 text-xl">
                  <Translate id="filters:featured title" />
               </span>
               <div className="grid grid-cols-1 gap-4 mb-12 lg:gap-5 lg:grid-cols-3 sm:grid-cols-2 tablet:grid-cols-2 xl:grid-cols-3">
                  {featured?.map(({ city, name, title, ...card }) => (
                     <CardItem
                        key={card._id}
                        name={name || title?.[locale]}
                        city={city?.name}
                        city_slug={city?.slug}
                        {...card}
                     />
                  ))}
               </div>
            </div>
         ) : null} */}
      </div>
   );
};
