import { Router } from 'next/router';
import { useEffect } from 'react';
import { useState } from 'react';
import Loading from '../common/loaders/loader';

export const FiltersLoading = () => {
   const [loading, setLoading] = useState(false);
   const startLoading = () => {
      setLoading(true);
   };
   const stopLoading = () => setLoading(false);

   Router.events.on('routeChangeStart', startLoading);
   Router.events.on('routeChangeComplete', stopLoading);

   useEffect(() => {
      return () => {
         Router.events.off('routeChangeStart', startLoading);
         Router.events.off('routeChangeComplete', stopLoading);
      };
   }, []);

   return loading ? (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-70">
         <div className="relative top-1/2">
            <Loading color="white" size={68} />
         </div>
      </div>
   ) : null;
};
