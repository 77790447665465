import capitalize from 'lodash/capitalize';
import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { BASE_URL } from '~/utils/theme';

interface EventSchemaProps {
   startDate?: string;
   endDate?: string;
   description?: string;
   name: string;
   image: string;
   eventAttendanceMode: 'offline' | 'online';
   offers: {
      validFrom: string;
      url: string;
      priceCurrency: string;
      price: string | number;
   };
   organizer: {
      name: string;
      url: string;
   };
   aggregateRating?: {
      reviewCount: string | number;
      ratingValue: number | string;
   };
   location: {
      name: string;
      address: {
         streetAddress: string;
         addressCountry: string;
         postalCode?: string;
         addessLocality?: string;
      };
   };
}

export const EventSchema = (props: EventSchemaProps) => {
   const router = useRouter();
   const {
      startDate,
      endDate,
      description,
      name,
      image,
      eventAttendanceMode,
      offers,
      organizer,
      aggregateRating,
      location,
   } = props;

   const schema = {
      '@context': 'https://schema.org',
      '@type': 'Event',
      eventStatus: 'https://schema.org/EventScheduled',
      ...(startDate && { startDate: String(startDate) }),
      ...(endDate && { endDate: String(endDate) }),
      url: BASE_URL + router.asPath,
      ...(description && { description: description }),
      name,
      image,
      eventAttendanceMode: `https://schema.org/${capitalize(
         eventAttendanceMode
      )}/EventAttendanceMode`,
      offers: {
         '@type': 'Offer',
         availability: 'https://schema.org/InStock',
         ...offers,
      },
      organizer: {
         '@type': 'Person',
         ...organizer,
      },
      ...(aggregateRating && {
         '@type': 'AggregateRating',
         ...aggregateRating,
      }),
      location: {
         '@type': 'Place',
         name: location.name,
         address: {
            '@type': 'PostalAddress',
            ...location.address,
         },
      },
   };

   return (
      <Head>
         <Script
            type="application/id+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schema, null, 3) }}
         />
      </Head>
   );
};
