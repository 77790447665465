import { LocationOutlineIcon } from '~/svgs/common';
import { getPrice } from '~/utils/get-experiences-slider';

import Card from '../common/card';
import { ExperienceProps } from '~/interfaces/experience';
import { Translate } from '~/i18n';
import cn from 'classnames';
import { memo } from 'react';
import { productClickImpressionDataLayer } from '~/analytics/gtm/ecommerce';
import { setBrowsedExperience } from '~/utils/set-browsed-experiences';
import { useAuth } from '~/auth';
import { useCurrency } from '~/providers';
import Button from '../common/button';

// import { StarIcon } from '~/svgs/common';
// import { visiualCitySlug } from '~/utils/get-city-slug';

export const CardItem = memo(
   ({
      _id,
      venue_image,
      name,
      slug,
      list_price,
      discounted_price,
      category,
      city_slug,
      city,
      // region,
      is_external,
      verified,
      book_online,
      is_dmc,
      optimized_image,
      type,
      venue_type,
      venue_type_title,
      event_card_image,
      price_start_from,
      organizer_name,
      is_small,
   }: ExperienceProps) => {
      const { authenticated, user } = useAuth();
      if (category?.slug === 'virtual-experiences') category.slug = 'online';
      const isVerified = verified === 'Yes' ? true : false;
      const isOnline = category?.slug === 'virtual-experiences';
      const price: any = getPrice(list_price, discounted_price);
      const { getConvertedPriceWithSymbol } = useCurrency();
      const ConvertedPriceWithSymbol: any = getConvertedPriceWithSymbol(price);
      list_price = discounted_price > list_price ? discounted_price : list_price;
      const online = !isOnline ? (
         <span className={cn('block h-auto overflow-hidden text-sm text-sta-primary truncate')}>
            {city}
         </span>
      ) : null;

      const _category = (
         <div className="ltr:text-xs rtl:text-sm line-clamp-1 font-semibold uppercase flex items-center mb-2 md:mb-4 cursor-default">
            {city ? (
               <div className="flex items-center gap-0.5 whitespace-nowrap">
                  <LocationOutlineIcon className="-mt-0.5" width={14} height={14} />
                  <span>{city}</span>
               </div>
            ) : null}
            <div className="line-clamp-1">
               {city ? <span className="mx-1 md:mx-2">|</span> : null}
               <span>{category?.title || ''}</span>
            </div>
         </div>
      );

      const discount = discounted_price > 0 &&
         list_price > 0 &&
         Number(discounted_price) !== Number(list_price) && (
            <span className="text-sm line-through opacity-75 ltr:ml-1 rtl:mr-1">
               {String(getConvertedPriceWithSymbol(list_price)).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
               )}
            </span>
         );

      return (
         <Card
            onClick={() => {
               productClickImpressionDataLayer({
                  products: [
                     {
                        list: 'Experience Listing',
                        brand: organizer_name || 'Hala Yalla',
                        category: category?.title || '',
                        city,
                        name,
                        date: '',
                        id: _id,
                        position: 1,
                        price: getPrice(list_price, discounted_price),
                        weekend: false,
                     },
                  ],
                  user: {
                     email: user?.email || '',
                     user_id: user?.user_id || '',
                     login_status: authenticated ? 'login' : '',
                  },
               });
               setBrowsedExperience(_id);
            }}
            optimized_image={optimized_image}
            is_dmc={is_dmc}
            bookable={book_online === 'Yes' || book_online === true}
            venueType={venue_type}
            venueTypeTitle={venue_type_title}
            image={event_card_image || venue_image}
            title={name}
            category={online}
            category_slug={category?.slug}
            isVerified={isVerified}
            type={type}
            venue_id={_id}
            href={
               type === 'events'
                  ? `/tickets/${slug}`
                  : `/experiences/${city_slug}/${category?.slug}/${slug}`
            }
            small={is_small}>
            <div className="flex flex-col md:flex-row justify-between p-4 pt-2 md:p-2 lg:h-[110px]">
               <div className="md:max-w-[61%] mb-4 md:mb-0">
                  {_category}
                  <div className="line-clamp-2 font-semibold text-primary-color text-xl md:text-lg">
                     {name}
                  </div>
               </div>
               <div className="flex flex-row-reverse md:flex-col gap-2 md:max-w-[34%] items-center md:items-end justify-between leading-[17.33px] md:leading-[19.81px]">
                  <div className="ltr:text-right rtl:text-left -mt-1 whitespace-nowrap">
                     {price_start_from ? (
                        <span className="block text-sm md:text-base font-semibold gap-1">
                           <Translate id="common:from" /> {price_start_from}
                        </span>
                     ) : venue_type !== 'offline_experience' ? (
                        <span className="flex flex-col items-end md:text-base">
                           <span>{discount}</span>
                           <span className="font-semibold">
                              {list_price > 0 || discounted_price > 0 ? (
                                 <span>
                                    <Translate id="common:from" /> {ConvertedPriceWithSymbol}
                                 </span>
                              ) : !is_external ? (
                                 <Translate id="common:free" />
                              ) : null}
                           </span>
                        </span>
                     ) : null}
                  </div>
                  <div className="flex flex-1 items-end">
                     <Button className="px-4 py-1.5 text-base font-semibold rounded-lg whitespace-nowrap">
                        <Translate id="common:book" />
                     </Button>
                  </div>
               </div>
            </div>
         </Card>
      );
   }
);
CardItem.displayName = 'CardItem';
