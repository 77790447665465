import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import jsCookie from 'js-cookie';
import Axios from '~/utils/axios';
import { useAuth } from '~/auth';
import Container from '../common/container';
import { Region } from '../home-page/cities-slider';
import { UserType } from '~/interfaces/user';
import { FilterCategory } from '~/interfaces/filters';
import { FiltersLoading } from './loader';
import { handleFilterCategoryChange } from './advanced-filters/handlers';
import CheckboxButton from './grouped-checkbox/checkbox-button';
import { useTranslate } from '~/i18n';
import { sentryCaptureException } from '~/errors/sentry';
import useMedia from '~/hooks/useMedia';
const FiltersSearchForm = dynamic(() => import('./search-form'), {
   ssr: false,
   loading: () => (
      <div className="flex justify-center grow w-full tablet:w-auto tablet:relative"></div>
   ),
});

interface FiltersPageLayoutProps {
   children: React.ReactNode;
   regions: Region[];
   categories: FilterCategory[];
}
export const FiltersPageLayout = ({ children, regions, categories }: FiltersPageLayoutProps) => {
   const { translate, lang } = useTranslate();
   const { authenticated, user, updateUser, logout } = useAuth<UserType>();
   const [error, setError] = useState(false);
   const showAllCategories = true;
   const limit = showAllCategories ? categories.length : 8;
   const router = useRouter();
   const onCategoryChange = (params: any) => handleFilterCategoryChange(params, router);
   const isMobile = useMedia(760);

   useEffect(() => {
      const getUser = async () => {
         if (authenticated && !user?.wishlist && !error) {
            try {
               const locale = lang.split('-')[1];
               // ! CHANGE TO TOKEN !!!
               const user_id = jsCookie.get('user_id');
               const { data } = await Axios.post('hyapp/user/profile', {
                  lang: locale,
                  user_id,
               });

               if (data.status?.toLowerCase() === 'success') {
                  updateUser({ ...data?.data, wishlist: data?.data?.wishlist_venues || [] });
               } else {
                  setError(true);
                  logout();
                  throw new Error(data?.error || data?.original?.error || 'Something went wrong');
               }
            } catch (error) {
               setError(true);
               console.error(error);
               sentryCaptureException(error);
            }
         }
      };
      getUser();
   }, [authenticated, user, lang, updateUser, error, logout]);

   return (
      <div>
         <FiltersLoading />
         <div className="min-h-[600px]">
            <div className="px-0 pt-5 mx-auto tablet:pt-7 ">
               <Container className="!px-0 tablet:mt-6">
                  <div className="tablet:rtl:mr-[23px] tablet:ltr:ml-[23px] ">
                     <Container className="tablet:ltr:!pr-0 tablet:rtl:!pl-0">
                        <div className="flex items-center justify-end mb-5">
                           <FiltersSearchForm
                              regions={regions}
                              categories={categories}
                              key={lang}
                           />
                        </div>
                        <div className="flex gap-[8px] lg:gap-[43px] items-center justify-start lg:justify-center overflow-x-auto scrollbar-hide">
                           <CheckboxButton
                              id="all"
                              name="all"
                              label={translate({ id: 'common:all' })}
                              theme={isMobile ? 'chip' : 'tab'}
                              icon={isMobile ? '' : '/images/all-category-icon.svg'}
                              onChange={(name, checked) =>
                                 onCategoryChange({ checked, name, type: 'category' })
                              }
                              isChecked={!router?.query?.categories}
                           />
                           {[...categories.slice(0, limit)].map(
                              ({
                                 title,
                                 _id,
                                 slugs,
                                 card_image,
                                 // total_venues
                              }) => {
                                 return (
                                    <CheckboxButton
                                       id={_id}
                                       key={`${_id}-category`}
                                       name={slugs?.en}
                                       label={title}
                                       // count={total_venues}
                                       theme={isMobile ? 'chip' : 'tab'}
                                       icon={card_image || '/images/celebration.svg'}
                                       onChange={(name, checked) =>
                                          onCategoryChange({ checked, name, type: 'category' })
                                       }
                                    />
                                 );
                              }
                           )}
                        </div>
                     </Container>
                  </div>
               </Container>
               <div className="bg-none lg:bg-white pt-8">
                  <Container>{children}</Container>
               </div>
            </div>
         </div>
      </div>
   );
};
