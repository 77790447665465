import querystring, { ParsedUrlQuery } from 'querystring';

import { NextRouter } from 'next/router';

type Params = {
   name: string;
   type: string;
   checked: boolean;
   childs?: any[];
};

export const handleFilterCategoryChange = (
   { name, type, checked, childs }: Params,
   router: NextRouter
) => {
   const { query } = router;
   const { lang, categories, location = 'all-cities', ...searchQueries } = query;
   const obj: Record<string, any> = { ...searchQueries };

   if (obj.page) delete obj.page;

   let cats = [...(categories || [])];

   if (type === 'category') {
      if (name === 'all') cats = [];
      else if (checked) cats = [name];
      else {
         /**
          * Removing the category from the list
          */
         cats = cats.filter(c => c !== name);
         /**
          * Removing subcategories from the query as weell
          */
         const subs = obj?.['sub-category'];
         if (subs) {
            let subs_list = String(subs).split(',');
            subs_list = subs_list.filter(s => !childs?.some(c => c.slug === s));
            subs_list.length
               ? (obj['sub-category'] = subs_list.join(','))
               : delete obj['sub-category'];
         }
      }
   } else if (type === 'sub-category') {
      const subs = obj['sub-category'];
      let sub_categories: string[] = subs?.split(',') || [];
      if (!checked) sub_categories = sub_categories.filter(c => c !== name);
      else sub_categories.push(name);

      sub_categories.length
         ? (obj['sub-category'] = sub_categories.join(','))
         : delete obj['sub-category'];
   }
   const search = Object.keys(obj).length ? `?${querystring.stringify(obj)}` : '';

   router.push(
      `/${lang}/${location}/experiences/${cats.length ? `c/${cats.join('/')}` : ''}${search}`
   );
};

export type MobileHandlerParams = {
   value: any;
   name: string;
   type: string;
   /**
    * Only in categories change
    */
   childs?: any[];
};
/**
 *
 * @param {object} state contains all the filter states,
 * this state should be applied on "Apply" click, thats why its reserved in a state
 */
export const objectifyFiltersState = (state: Record<string, any>, params: MobileHandlerParams) => {
   const { name, type, value, childs } = params;
   const clone = { ...state };

   if (['sub-category', 'category'].includes(type)) {
      let cats: string[] = clone[type]?.split(',') || [];

      if (value) cats.push(name);
      else {
         cats = cats.filter(c => c !== name);
         if (type === 'category') {
            /**
             * Removing subcategories from the query as weell
             */
            const subs = clone?.['sub-category'];
            if (subs) {
               let subs_list = String(subs).split(',');
               subs_list = subs_list.filter(s => !childs?.some(c => c.slug === s));
               subs_list.length
                  ? (clone['sub-category'] = subs_list.join(','))
                  : delete clone['sub-category'];
            }
         }
      }

      cats.length ? (clone[type] = cats.join(',')) : delete clone[type];
   } else if (name === 'price') {
      const [min, max] = value;
      if (!min && !max) {
         delete clone['price'];
      } else {
         /**
          * Min & Max prices should be joined via comma
          */
         clone.price = `${min || ''},${max || ''}`;
      }
   } else if (['females', 'males'].includes(name)) {
      /**
       * Since genders are going to be a string joined by ","
       * we need to split them in case they exist in the URL
       */
      let genders: string[] = clone.gender?.split(',') || [];
      /**
       * If values is "true", we push the gender, else, we pop it
       */
      value ? genders.push(name) : (genders = genders.filter(g => g !== name));
      /**
       * Remove genders from the query in case none were selected
       */
      genders.length ? (clone.gender = genders.join(',')) : delete clone.gender;
   } else if (
      [
         'internal',
         'external',
         '24_hours_confirmation',
         'online_experience',
         'offline_experience',
      ].includes(name)
   ) {
      /**
       * Since genders are going to be a string joined by ","
       * we need to split them in case they exist in the URL
       */
      let venue_types: string[] = clone.venue_types?.split(',') || [];
      /**
       * If values is "true", we push the gender, else, we pop it
       */
      value ? venue_types.push(name) : (venue_types = venue_types.filter(t => t !== name));
      /**
       * Remove genders from the query in case none were selected
       */
      venue_types.length ? (clone.venue_types = venue_types.join(',')) : delete clone.venue_types;
   } else if (['families', 'kids', 'solo', 'couples', 'ladies'].includes(name)) {
      /**
       * Since genders are going to be a string joined by ","
       * we need to split them in case they exist in the URL
       */
      let interests: string[] = clone.interest?.split(',') || [];
      /**
       * If values is "true", we push the gender, else, we pop it
       */
      value ? interests.push(name) : (interests = interests.filter(g => g !== name));
      /**
       * Remove interest from the query in case none were selected
       */
      interests.length ? (clone.interest = interests.join(',')) : delete clone.interest;
   } else if (name === 'location' && value?.length) {
      clone.location = Array.isArray(value) ? value?.join(',') : value;
   } else if (value) {
      clone[name] = value;
   } else delete clone[name];
   return clone;
};

export const getStateFromRouter = (query: ParsedUrlQuery) => {
   const { categories, ...searchQueries } = query;
   delete searchQueries.lang;
   const obj: Record<string, any> = {};

   Object.entries(searchQueries).forEach(([type, value]) => {
      switch (type) {
         case 'offers':
            obj.offers = value === 'true';
            break;
         default:
            obj[type] = value;
      }
   });
   if (categories?.length) {
      obj.category = (categories as string[]).join(',');
   }
   return obj;
};
