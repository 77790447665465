import { format } from 'date-fns';
import { ProductImpression } from './../analytics/gtm/ecommerce';
import { ExperienceProps } from '~/interfaces/experience';

type Params<T> = {
   list: string;
   item: T;
   position: number;
};
export const normalizeExperience = ({
   item,
   position,
   list,
}: Params<ExperienceProps>): ProductImpression => ({
   city: item.city?.name || item.city || '',
   brand: item.organizer_name || 'Hala Yalla',
   category: item.category?.title || '',
   id: item._id,
   name: item.name,
   position,
   list,
   weekend: false,
   date: '',
   price: item.discounted_price || item.list_price,
});

export const isWeekend = (date: number) => {
   const d = format(new Date(date * 1000), 'EEEE').toLocaleLowerCase();
   return d === 'saturday' || d === 'sunday';
};

export const normalizeEvent = ({ item, list, position }: Params<any>): ProductImpression => ({
   id: item.id,
   name: item.title,
   brand: 'Hala Yalla',
   category: 'Events',
   city: item.city,
   date:
      item.start_date_time && item.end_date_time
         ? `${format(new Date(item.start_date_time * 1000), 'LLL dd')} - ${format(
              new Date(item.end_date_time * 1000),
              'LLL dd'
           )}`
         : '',
   list,
   position,
   price: item.price || item.event_start_price + item.event_start_price_vat,
   weekend:
      item.start_date_time && item.end_date_time
         ? isWeekend(item.start_date_time) || isWeekend(item.end_date_time)
         : false,
});
