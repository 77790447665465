import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useTranslate } from '~/i18n';
import { parseMessage } from '~/i18n/_helpers';
import { ExperienceProps } from '~/interfaces/experience';
import { FilterCategory } from '~/interfaces/filters';
import { BASE_URL } from '~/utils/theme';
import { SearchResultsSchema } from '../common/page-schema';
import { SearchPageSchemaResultItem } from '../common/page-schema/search-results-schema';
import { Region } from '../home-page/cities-slider';
import { FiltersPageExperiencesList } from './experiences-list';
import { FiltersPageLayout } from './filters-layout';

export type FiltersPageProps = {
   categories: FilterCategory[];
   regions: Region[];
   featured: any[];
   experiences: {
      current_page: number;
      data: ExperienceProps[];
      total: number;
      per_page: number;
      last_page: number;
      seo?: {
         meta_description_ar: string;
         meta_description_en: string;
         meta_keywords_ar: string;
         meta_keywords_en: string;
         meta_title_ar: string;
         meta_title_en: string;
      } & { [key: string]: string };
      count?: {
         experiences: number;
         events: number;
      };
   };
};

export const FiltersPageContent = ({ categories, regions, experiences }: FiltersPageProps) => {
   const { locale, translate } = useTranslate();
   const { query } = useRouter();

   const schemaResults: SearchPageSchemaResultItem[] = useMemo(() => {
      return experiences.data?.map(item => {
         const URL =
            item.type === 'events'
               ? `/tickets/${item.slug}`
               : `/experiences/${item.city_slug_filter || item.city_slug + ''}/${item.category?.slug}/${
                    item.slug
                 }`;

         const obj: SearchPageSchemaResultItem = {
            image: item.thumbnail,
            name: item.name,
            url: BASE_URL + URL,
            offers: {
               priceCurrency: 'SAR',
               url: BASE_URL + URL,
               price: item.discounted_price ?? item.list_price,
            },
         };
         return obj;
      });
   }, [experiences.data]);

   /**
    * Location used to parse the SEO content
    */
   const meta_location = useMemo(() => {
      if (!query.location || query.location === 'all-cities') {
         return translate({ id: 'common:saudi arabia' });
      }
      return (
         regions.find(region => region.slug === query.location)?.name ||
         translate({ id: 'common:saudi arabia' })
      );
   }, [regions, translate, query.location]);

   const parseSEOContentWithLocation = (message?: string) =>
      parseMessage(message || '', { location: meta_location });

   const parsedTitle =
      parseSEOContentWithLocation(experiences.seo?.[`meta_title_${locale}`]) ||
      translate({ id: 'seo:search title' });

   const parsedDescription =
      parseSEOContentWithLocation(experiences.seo?.[`meta_description_${locale}`]) ||
      translate({ id: 'seo:experiences description' });
   const parsedKeywords = parseSEOContentWithLocation(experiences.seo?.[`meta_keywords_${locale}`]);

   return (
      <FiltersPageLayout regions={regions} categories={categories}>
         <SearchResultsSchema results={schemaResults} title={parsedTitle} />
         <NextSeo
            title={parsedTitle}
            description={parsedDescription}
            openGraph={{
               title: parsedTitle,
            }}
            additionalMetaTags={[{ name: 'keywords', content: parsedKeywords }]}
         />
         <FiltersPageExperiencesList {...experiences} />
      </FiltersPageLayout>
   );
};
